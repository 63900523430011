import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Login from './Login';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [files, setFiles] = useState({ files: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 10;

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      axios.defaults.headers.common['x-access-token'] = token;

      const fetchFiles = async () => {
        try {
          const response = await axios.get('https://photo-be.ajwedding.eu/files');
          setFiles({ files: response.data.files });
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      };

      fetchFiles();
    }
  }, [token]);

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['x-access-token'];
  };

  // Pagination
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = files.files && files.files.length > 0
    ? files.files.slice(indexOfFirstFile, indexOfLastFile)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getThumbnail = (file) => {
    const { key, url } = file;
    if (key.endsWith('.mp4') || key.endsWith('.avi')) {
      return (
        <div className="flex justify-center items-center h-24">
          <video className="w-24 h-24" controls>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if (key.endsWith('.jpg') || key.endsWith('.png')) {
      return (
        <div className="flex justify-center items-center h-24">
          <img src={url} alt={key} className="w-24 h-24 object-cover" />
        </div>
      );
    } else {
      return <span>{key}</span>;
    }
  };

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="container mx-auto">
        <h1 className="text-3xl mb-4 text-center">Andreea & Joe Media</h1>
        <button onClick={handleLogout} className="mb-4 py-2 px-4 bg-red-500 text-white rounded hover:bg-red-700">
          Logout
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {currentFiles.map((file) => (
            <div key={file.key} className="bg-white p-4 rounded shadow-md">
              {getThumbnail(file)}
              <a
                href={file.url}
                download
                className="block mt-2 text-center text-blue-500 hover:underline"
              >
                Download
              </a>
            </div>
          ))}
        </div>
        {/* Pagination */}
        <ul className="flex justify-center mt-4">
          {Array.from({ length: Math.ceil(files.files.length / filesPerPage) }, (_, index) => (
            <li key={index} className="mx-2">
              <button
                className={`px-3 py-1 rounded-md ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-100 hover:bg-gray-200'}`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
